import React from "react"
import styled from "styled-components"
import benefit1 from "../images/benefits/benefit-1.svg"
import benefit2 from "../images/benefits/benefit-2.svg"
import benefit3 from "../images/benefits/benefit-3.svg"
import benefit4 from "../images/benefits/benefit-4.svg"
import { Container } from "@material-ui/core"
import { breakpoints } from "./variables"

const BenefitsW = styled.div`
  padding: 6rem 0;
  position: relative;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 4rem 0;
  }
`
const BenefitsIW = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-wrap: wrap;
  }
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 23%;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  background-color: ${props => props.theme.palette.background.main};
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 49%;
    &:not(first-child) {
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`
const Icon = styled.img``
const Title = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  min-height: 5rem;
`
const Text = styled.div`
  font-size: 1.5rem;
  align-self: flex-end;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`

const Benefits = () => {
  return (
    <BenefitsW>
      <Container>
        <BenefitsIW>
          <Group>
            <Icon src={benefit1} alt="Automate your back office"></Icon>
            <Title>Automate your back office</Title>
            <Text>
              Gain control of your data in real-time with visibility and
              traceability of transactions from beginning to end.
            </Text>
          </Group>
          <Group>
            <Icon
              src={benefit2}
              alt="Reap the benefits of a cloud-connected solution"
            ></Icon>
            <Title>Reap the benefits of a cloud-connected solution</Title>
            <Text>
              Streamline your business by connecting your Sage Software with
              both legacy and modern applications.
            </Text>
          </Group>
          <Group>
            <Icon
              src={benefit3}
              alt="Ensure data integrity across systems"
            ></Icon>
            <Title>Ensure data integrity across systems</Title>
            <Text>
              Increase accuracy with the ability to easily audit data in all
              systems giving you one version of truth.
            </Text>
          </Group>
          <Group>
            <Icon
              src={benefit4}
              alt="Get rid of manual business processes"
            ></Icon>
            <Title>Get rid of manual business processes</Title>
            <Text>
              Increase employee productivity by eliminating duplicate entry and
              avoiding manipulation of data to get the information you need
              when, where and how you want it.
            </Text>
          </Group>
        </BenefitsIW>
      </Container>
    </BenefitsW>
  )
}

export default Benefits
