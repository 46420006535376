import React from "react"
import styled from "styled-components"
import animation from "../images/animation.svg"
import EmailSubmitter from "./email-submitter"
import { Container } from "@material-ui/core"
import { breakpoints } from "./variables"

const IllustrationW = styled.div`
  padding-top: 6rem;
  padding-bottom: 3rem;
  color: ${props => props.theme.palette.primary.main};
  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 4rem;
  }
`
const IllustrationIW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`
const TextW = styled.div`
  width: 49%;
  font-family: "Montserrat-Medium", sans-serif;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-bottom: 2rem;
  }
`
const Title = styled.h1`
  font-size: 3rem;
  font-weight: initial;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }
`
const Text = styled.h6`
  font-size: 1.7rem;
  font-weight: initial;
  margin: 0 0 2rem 0;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 3rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
const AnimationW = styled.div`
  width: 49%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 70%;
    margin-top: 1rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
  }
`

const Illustration = () => {
  return (
    <IllustrationW>
      <Container>
        <IllustrationIW>
          <TextW>
            <Title>
              Connecting Sage to cloud solutions with <b>DataMap</b>
            </Title>
            <Text>
              Integrate everything to Sage Software. From web carts, to business
              applications, to your vendor’s system, enable your cloud connected
              Sage Software system to do more for you.
            </Text>
            <EmailSubmitter width="85%" />
          </TextW>
          <AnimationW>
            <img src={animation} alt="illustration" />
          </AnimationW>
        </IllustrationIW>
      </Container>
    </IllustrationW>
  )
}

export default Illustration
