import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"
import howItWorks1 from "../images/how-it-works/how-it-works-1.svg"
import howItWorks2 from "../images/how-it-works/how-it-works-2.svg"
import howItWorks3 from "../images/how-it-works/how-it-works-3.svg"
import dataMapMotionLogo from "../images/datamap-motion-logo.svg"
import { breakpoints } from "./variables"

const HowItWorksW = styled.div``
const HowItWorksIW = styled.div`
  padding-top: 3rem;
  position: relative;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 2rem;
  }
`
const DataMapMotionLogo = styled.img`
  position: absolute;
  left: -100%;
  top: 0%;
  z-index: -1;
  opacity: 0.025;
  width: 150%;
  max-width: initial;
  @media screen and (max-width: ${breakpoints.sm}) {
    left: -60%;
  }
`
const HeaderW = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Header = styled.h3`
  font-size: 4rem;
  text-align: center;
  font-weight: normal;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }
  &:hover {
    .borderBottom {
      width: 120% !important;
    }
  }
`
const Link = styled.a``

const BorderBottom = styled.div`
  width: 50%;
  border: 2px solid rgb(8, 31, 58);
  transition: width 1s;
`

const HeaderText = styled.h6`
  width: 75%;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 95%;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const ItemsW = styled.div``
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    &:nth-child(2n) {
      flex-direction: column;
    }
  }
`
const Image = styled.img`
  width: 45%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 49%;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`
const TextW = styled.div`
  width: 45%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 49%;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 1rem;
    width: 100%;
  }
`
const Title = styled.h5`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0;
  @media screen and (max-width: ${breakpoints.sm}) {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
`
const Text = styled.p`
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`

const HowItWorks = () => {
  return (
    <HowItWorksW>
      <Container>
        <HowItWorksIW>
          <HeaderW>
            <Header>
              <Link href="/how-it-works">How it works</Link>
              <BorderBottom className="borderBottom" />
            </Header>
            <HeaderText>
              DataMap automatically synchronizes data without needing to extract
              files. This web-based integration increases employee productivity
              by reducing the amount of time spent entering and validating data
              between systems, which gives you visibility and traceability of
              transactions from beginning to end. Sync your data seamlessly
              today!
            </HeaderText>
          </HeaderW>
          <ItemsW>
            <Item>
              <Image
                src={howItWorks1}
                alt="Save time and increase accuracy"
              ></Image>
              <TextW>
                <Title>Save time and increase accuracy</Title>
                <Text>
                  Imagine an order from your online shopping cart appear as a
                  Sales Order in your Sage Software system without a human
                  touch.
                </Text>
              </TextW>
            </Item>
            <Item>
              <Image
                src={howItWorks2}
                alt="Increase employee productivity"
              ></Image>
              <TextW>
                <Title>Increase employee productivity</Title>
                <Text>
                  Accelerate your business and eliminate data silos by
                  connecting SAP Concur and Sage. Automatically sync data
                  between Concur Invoice and Concur Expense including invoices,
                  payment and vendors without needing to extract files.
                </Text>
              </TextW>
            </Item>
            <Item>
              <Image src={howItWorks3} alt="Streamline your sales"></Image>
              <TextW>
                <Title>Streamline your sales</Title>
                <Text>
                  Automatically populate Amazon orders in Sage without a human
                  touch. When Sage fulfills the order, updated inventory
                  quantities and tracking numbers flow into Amazon seamlessly.
                  Save time by eliminating the need for extracts and
                  manipulating data and begin accurately accounting for orders
                  with Amazon.
                </Text>
              </TextW>
            </Item>
          </ItemsW>
          <DataMapMotionLogo
            src={dataMapMotionLogo}
            alt="datamap motion logo"
          />
        </HowItWorksIW>
      </Container>
    </HowItWorksW>
  )
}

export default HowItWorks
