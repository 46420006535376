import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Illustration from "../components/illustration"
import Benefits from "../components/benefits"
import HowItWorks from "../components/how-it-works"

import curve from "../images/curve.svg"

const CurveW = styled.div`
  padding-bottom: 3vw;
  background-color: ${props => props.theme.palette.datamap.main};
  position: relative;
  &::before {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 2vw;
    background-image: url(${curve});
    background-position-x: center;
    background-size: 110vw;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <CurveW>
      <Illustration />
    </CurveW>
    <Benefits />
    <HowItWorks />
  </Layout>
)

export default IndexPage
